import React, { Component } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { connect } from "react-redux";
import { DIR_RTL } from 'constants/ThemeConstant';
import { Auth } from 'aws-amplify';
import { signOutSuccess } from 'redux/actions/Auth';

export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  signOut = () => {
    try {
      Auth.signOut();
      this.props.signOutSuccess();
      localStorage.removeItem('auth_token');
    } catch (error) {
      console.log('Error sign out: ', error);
    }
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
          <Menu.Item onClick={() => this.signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement={this.props.direction === DIR_RTL ? 'left' : 'right'}
          width={350}
          onClose={() => this.onClose()}
          visible={this.state.visible}>
          <ThemeConfigurator />
        </Drawer>
      </>
    );
  }
}
const mapDispatchToProps = {
  signOutSuccess,
}

const mapStateToProps = ({ auth }) => {
  const { signOutSuccess } = auth;
  return { signOutSuccess }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanel);
