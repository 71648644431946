import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';

const themes = {
  dark: `https://mobile-key.apps.gustaffo.com/css/dark-theme.css`,
  light: `https://mobile-key.apps.gustaffo.com/css/light-theme.css`,
};

function App() {

  try {
    return (
      <div className="App">
        <Provider store={store.store}>
          <PersistGate persistor={store.persistor} loading={null} >
            <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
              <Router>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </ThemeSwitcherProvider>
          </PersistGate>
        </Provider>
      </div>
    );
  } catch(e) {
    localStorage.clear();
    sessionStorage.clear();

    return (
      <div className="App">
        <Provider store={store.store}>
          <PersistGate persistor={store.persistor} loading={null} >
            <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
              <Router>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </ThemeSwitcherProvider>
          </PersistGate>
        </Provider>
      </div>
    );
  }
}

export default App;
