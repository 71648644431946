import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/main`} component={lazy(() => import(`./main-page`))} />
        <Route path={`${APP_PREFIX_PATH}/userdetail`} component={lazy(() => import(`./user-detail`))} />
        <Route path={`${APP_PREFIX_PATH}/userupdate`} component={lazy(() => import(`./user-detail`))} />
        <Route path={`${APP_PREFIX_PATH}/listlocks`} component={lazy(() => import(`./list-locks`))} />
        <Route path={`${APP_PREFIX_PATH}/lockdetail`} component={lazy(() => import(`./lock-detail`))} />
        <Route path={`${APP_PREFIX_PATH}/resmngmt`} component={lazy(() => import(`./create-reservation`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/main`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);